import React, { useState, useEffect } from "react"
import { useStaticQuery, graphql, Link } from "gatsby"
import { useLunr } from "../hooks/useLunr"

import Layout from "../components/layout"
import SEO from "../components/seo"
import Loader from "../components/loader"

const Search = ({ location }) => {
  const data = useStaticQuery(graphql`
    query {
      localSearchPosts {
        index
        store
      }
    }
  `)

  const { index, store } = data.localSearchPosts
  const [query, setQuery] = useState(null)
  const results = useLunr(query, index, store)

  const [loading, setLoading] = useState(true)

  useEffect(() => {
    setLoading(true)

    const url = new URLSearchParams(window.location.search)
    if (location.state) {
      setQuery(location.state.query)
    } else if (url) {
      setQuery(url.get("s"))
    }

    setTimeout(function() {
      setLoading(false)
    }, 1000)
  }, [location, query, results])

  if (loading) {
    return (
      <Layout style={{ maxWidth: "1100px" }}>
        <SEO title="Iqara Islam | Sua Fonte de Islam Sunita" />

        <div className="main" style={{ minHeight: "51vh" }}>
          {/* <h2 className="posts-heading">Carregando...</h2> */}
          <div className="loader-container">
            <Loader />
          </div>
        </div>
      </Layout>
    )
  }

  return (
    <Layout style={{ maxWidth: "1100px" }}>
      <SEO title="Iqara Islam | Sua Fonte de Islam Sunita" />

      <div className="main" style={{ minHeight: "51vh" }}>
        <h2 className="posts-heading">Resultados para: {query}</h2>

        {!results.length && (
          <p className="search-no-results">
            Nenhum resultado encontrado para {query}
          </p>
        )}

        <div className="posts">
          {results.map(post => (
            <Link to={post.slug} className="post" key={post.slug}>
              <figure>
                <img src={post.image} />
              </figure>
              <h2 dangerouslySetInnerHTML={{ __html: post.name }}></h2>
            </Link>
          ))}
        </div>
      </div>
    </Layout>
  )
}

export default Search
